import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import { Fragment, memo } from 'react'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/core'
import { capitalCase } from 'change-case'

import { initializeApollo } from 'lib/apollo'

import { changeCase } from '@/ressources/utils'

import GET_ALL_SHOPS from 'core/queries/shopsQuery'
import { shopsVariables, shops } from 'core/queries/__generated__/shops'

import Layout from '@/ressources/Layout'
import Wrapper from '@/ressources/Wrapper'
import Span from '@/ressources/Span'
import Icon from '@/ressources/Icon'
import { IconName } from '@/ressources/svg'

import * as colors from '@/ressources/colors'
import * as breakpoints from '@/ressources/breakpoints'
import * as grid from '@/ressources/grid'

const header = css`
  position: relative;
  margin-bottom: 2em;
  overflow: hidden;

  > img {
    width: 100%;
    height: calc(215px + 5vw);
    object-fit: cover;

    @media (${breakpoints.HUGE}) {
      height: 365px;
    }
  }

  @media (${breakpoints.MEDIUM}) {
    margin-bottom: 4em;
  }
`

const headerContent = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  text-align: center;

  @media (${breakpoints.X_SMALL}) {
    width: 65%;
  }

  @media (${breakpoints.SMALL}) {
    width: 50%;
  }

  > div {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      right: -3.5em;
      transform: translateY(-50%);
      width: auto;
      height: 60%;
      max-height: 5em;

      @media (${breakpoints.X_SMALL}) {
        height: 70%;
        max-height: 6em;
        right: -4.5em;
      }

      @media (${breakpoints.SMALL}) {
        height: 80%;
        max-height: 6em;
        right: -5.5em;
      }

      @media (${breakpoints.MEDIUM}) {
        height: 100%;
        max-height: 7em;
        right: -7.5em;
      }

      @media (${breakpoints.LARGE}) {
        height: 100%;
        max-height: 7em;
      }
    }
  }

  h1 {
    font-family: 'Geomanist-Black';
    font-size: calc(3.5vw + 0.9rem);
    line-height: calc(3.5vw + 0.9rem);
    color: white;

    @media (${breakpoints.LARGE}) {
      font-size: 3.2rem;
      line-height: 3.2rem;
    }
  }

  h2 {
    font-family: 'Archivo-SemiBold';
    font-size: calc(2.3vw + 0.5rem);
    line-height: 1.1rem;
    margin-bottom: 3vw;
    color: white;

    @media (${breakpoints.SMALL}) {
      font-size: calc(2vw + 0.2rem);
      line-height: 1.2rem;
      margin-bottom: 2vw;
    }

    @media (${breakpoints.LARGE}) {
      font-size: 1.5rem;
      line-height: 3.2rem;
      margin-bottom: 0;
    }
  }
`

const root = css`
  max-width: 60em;
  text-align: center;

  h1 {
    font-family: 'Archivo-SemiBold';
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: ${colors.NEUTRAL_90};

    @media (${breakpoints.MEDIUM}) {
      font-size: 1.875rem;
      line-height: 2.7rem;
    }
  }

  h2 {
    font-family: 'LibreFranklin-Regular';
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: ${colors.NEUTRAL_55};
    margin-top: 0.5em;

    @media (${breakpoints.MEDIUM}) {
      font-size: 1.15rem;
      line-height: 1.2rem;
      margin-top: 0.2em;
    }
  }

  @media (${breakpoints.LARGE}) {
    margin: 0 auto !important;
  }
`

const shopsWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  padding-bottom: 2em;

  @media (${breakpoints.SMALL}) {
    display: grid;
    grid-column-gap: ${grid.DEFAULT_GUTTER};
    grid-row-gap: calc(${grid.DEFAULT_GUTTER} / 2);
    grid-template-columns: 1fr 1fr;
  }

  @media (${breakpoints.MEDIUM}) {
    padding-bottom: 3.5em;
  }
`

const shopContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.25em;

  > * {
    text-align: left;
  }

  :hover span,
  :hover div {
    opacity: 1;
    visibility: visible;
    transition: all ease 200ms;
  }

  @media (${breakpoints.MEDIUM}) {
    margin-bottom: 0.25em;
  }
`

const shopContent = css`
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  align-items: center;
  margin-top: 0.5rem;

  > * {
    width: 100%;
    text-align: left;
    line-height: 1.5em;
  }

  h2 {
    font-family: 'Archivo-SemiBold' !important;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    color: ${colors.BRAND_50} !important;
  }

  h1 {
    font-family: 'Archivo-SemiBold' !important;
    font-size: 1.125rem !important;
    line-height: 1.4375rem !important;
    color: ${colors.NEUTRAL_90} !important;
  }

  span {
    font-family: 'LibreFranklin-Regular';
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: ${colors.NEUTRAL_50};
  }

  @media (${breakpoints.MEDIUM}) {
    a {
      pointer-events: none;
    }
  }
`

const imageContainer = css`
  pointer-events: auto;
  overflow: hidden;

  & > div {
    border: 1px solid ${colors.NEUTRAL_30};
    border-radius: 6px;
  }

  img {
    transition: transform ease 1s;
  }

  img:hover {
    cursor: pointer;
    transform: scale(1.15);
    transition: transform ease-out 2s;
  }
`

const shopDescription = css`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  line-height: 1.4em;

  @media (${breakpoints.SMALL}) {
    line-height: 1.5em;
  }
  @media (${breakpoints.MEDIUM}) {
    margin-top: 0.3rem;
    margin-bottom: 0.6rem;
  }
`

const clamping = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const buttonContainer = css`
  pointer-events: all;
  display: none;
  visibility: hidden;
  align-items: center;

  span {
    padding-right: 5px;
  }

  span,
  div {
    opacity: 0;
    transition: all ease 0.2s;
  }

  :hover {
    span {
      color: ${colors.BRAND_60};
    }
    div {
      transform: translateX(4px);
      transition: all ease 0.2s;
    }
  }

  @media (${breakpoints.MEDIUM}) {
    display: flex;
  }
`

const EDGES_PER_FETCH = 50
const EXCLUDED_FRANCHISE = 'Cavavin'
const Index: React.FC<{}> = () => {
  const query = useQuery<shops, shopsVariables>(GET_ALL_SHOPS, {
    variables: {
      first: EDGES_PER_FETCH,
      where: { not: { franchise: EXCLUDED_FRANCHISE } },
    },
    fetchPolicy: 'cache-first',
  })

  if (query.data) {
    return (
      <Fragment>
        <Head>
          <title>Potico | Caves et épiceries de passionnés</title>
          <meta
            name="description"
            content="Commandez en ligne les produits de vos cavistes et épiceries fines préférés : vins, spiritueux, coffrets gourmands, chocolats etc. Recevez les directement chez vous par livraison ou optez pour le retrait en boutique."
          />
        </Head>
        <Layout hideCart>
          <div css={header}>
            <div css={headerContent}>
              <h2>La plateforme des Potis commerçants</h2>
              <div>
                <h1>Osons rêver d’une bonne bouteille ou d'un pâté en croûte !</h1>
                <img
                  src="/image_approved.png"
                  alt={`Image certification`}
                  width="105"
                  height="110"
                  draggable={false}
                />
              </div>
            </div>
            <img src="/image_home.jpg" alt={`Image accueil`} draggable={false} />
          </div>
          <Wrapper css={root}>
            <h1>Caves et épiceries de passionnés</h1>
            <h2>Sélection de produits par et pour les bons vivants</h2>
            <div css={shopsWrapper}>
              {query.data.shops.edges.map(({ node }) => {
                return node.active ? (
                  <div key={node.id} css={shopContainer}>
                    <div css={shopContent} className="flex flex-col">
                      <Link href={`/${node.id}`} passHref>
                        <a aria-label="rediriger vers boutique">
                          <div css={imageContainer} className="mb-2">
                            <Image
                              src={node.images[0]?.uri ?? '/illu_shopplaceholder.png'}
                              alt={`Image boutique ${node.name}`}
                              title={node.description}
                              width="465"
                              height="225"
                              objectFit="cover"
                              objectPosition="center center"
                              quality="50"
                              draggable="false"
                            />
                          </div>
                          <h2>
                            {capitalCase(node.address.city) +
                              (node.address.postCode ? ', ' + node.address.postCode : '')}
                          </h2>
                          <h1>{changeCase(node.name)}</h1>
                          <span css={[shopDescription, clamping]}>{node.description}</span>
                        </a>
                      </Link>
                    </div>
                    <Link href={`/${node.id}`} passHref>
                      <a aria-label="rediriger vers boutique">
                        <div css={buttonContainer} className="flex flex-row">
                          <Span size="medium" weight="semibold" color="brand">
                            Visiter la boutique
                          </Span>
                          <Icon
                            name={IconName.ArrowLine}
                            color={colors.BRAND_50}
                            size={20}
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                ) : null
              })}
            </div>
          </Wrapper>
        </Layout>
      </Fragment>
    )
  } else {
    return null
  }
}

export default memo(Index)

export async function getServerSideProps() {
  const apollo = initializeApollo()

  await apollo.query({
    query: GET_ALL_SHOPS,
    variables: {
      first: EDGES_PER_FETCH,
      where: { not: { franchise: EXCLUDED_FRANCHISE } },
    },
  })

  return {
    props: { initialApolloState: apollo.cache.extract() },
  }
}
